import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/Seo";
import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";

import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, connectHits } from "react-instantsearch-dom";
import { v4 } from "uuid";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);
let replacements = [];
function checkSearchMatchLevel(hit) {
  let matched = 0;

  if (
    hit._highlightResult.content &&
    hit._highlightResult.content.matchLevel !== "none"
  ) {
    matched++;
  }
  if (
    hit._highlightResult.summary &&
    hit._highlightResult.summary.matchLevel !== "none"
  ) {
    matched++;
  }
  if (
    hit._highlightResult.title &&
    hit._highlightResult.title.matchLevel !== "none"
  ) {
    matched++;
  }
  if (matched) {
    if (hit.content && hit.content.length > 256) {
      return hit.content.substring(0, 256) + " ... ";
    } else {
      return hit.content;
    }
  }
}
const SearchHits = connectHits(({ hits }) => {
  // hacky: uses a global variable "replacements" instead of being passed by argument
  return (
    <>
      {hits.length ? (
        <>
          {hits.map((hit) => {
            let content;

            if ((content = checkSearchMatchLevel(hit))) {
              if (replacements) {
                let keys = Object.keys(replacements);
                if (keys.length) {
                  keys.forEach((key) => {
                    content = content.replace(
                      new RegExp(key, "g"),
                      replacements[key]
                    );
                  });
                }
              }

              return (
                <div className="content hover-highlight" key={v4()}>
                  <hr />
                  <h4 css={{ marginBottom: 0 }}>
                    <Link className="c-inh" to={hit.slug}>
                      {hit.title}
                    </Link>
                  </h4>
                  <Link to={hit.slug}>
                    {hit.subtitle ? (
                      <h5 css={{ marginBottom: 0 }}>{hit.subtitle}</h5>
                    ) : null}
                  </Link>
                  <div>{content}</div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </>
      ) : null}
    </>
  );
});

// eslint-disable-next-line
export const SearchPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  helmet,
  config,
  site,
}) => {
  const PageContent = HTMLContent || Content;
  //console.log(breadcrumbs);
  let heroImage, footerImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;

  const showTitle = options.showTitle;

  let siteUrl = site.url;
  let siteName = site.name;
  let sitePhone = "";

  if (config.url) siteUrl = config.url;
  if (config.name) siteName = config.name;
  if (config.phone) sitePhone = config.phone;

  replacements["{siteurl}"] = siteUrl;
  replacements["{siteName}"] = siteName;
  replacements["{sitePhone}"] = sitePhone;

  if (config.location && config.location.length) {
    if (config.location[0] && config.location[0].address)
      replacements["{address_1}"] = config.location[0].address;
    if (config.location[1] && config.location[1].address)
      replacements["{address_2}"] = config.location[1].address;
    if (config.location[2] && config.location[2].address)
      replacements["{address_3}"] = config.location[2].address;
  }

  return (
    <div>
      {helmet || null}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="content">
              <DisplayContentHeader title={title} breadcrumbs={breadcrumbs} />
            </div>

            <PageContent className="content" content={body} />

            <InstantSearch indexName="Pages" searchClient={searchClient}>
              <SearchBox value="" />
              <br />
              <SearchHits />
            </InstantSearch>
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

SearchPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const SearchPage = ({ data }) => {
  const { markdownRemark: post } = data;
  let { site, config } = data;
  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  //  config = config.frontmatter.site;
  //  site = site.siteMetadata.property;
  return (
    <Layout>
      <SearchPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        config={config.frontmatter.site}
        site={site.siteMetadata.property}
      />
    </Layout>
  );
};

SearchPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SearchPage;

export const searchPageQuery = graphql`
  query SearchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        breadcrumbs {
          parent
          parentSlug
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitle
        }
        summary
      }
    }
    site {
      siteMetadata {
        property {
          url
          name: siteName
        }
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        site {
          url
          name
          phone
          location {
            address
          }
        }
      }
    }
  }
`;
